const config = {
  environment: 'production', // 'staging' 'production'
  healthie: {
    apiUrl: 'https://app.gethealthie.com/graphql',
  },
  dataFunctions: {
    key: '222ac4e7-25e3-48ec-8fe2-8841c4535c51', // firease api key deployed to firebase
    versionURL: 'https://us-central1-calibrate-app.cloudfunctions.net/api/version', // cloud function that exposes current version of program data
    dataURL: 'https://us-central1-calibrate-app.cloudfunctions.net/api/data', // cloud function that exposes program data
  },
}
export default config
